import initialState from '../state';
import * as actionType from '../action';

const specialities = (state = initialState, action ) => {
  switch (action.type) {
    case actionType.GET_SELECTOR_SPECIALTIES:
      return {
        ...state,
        allSpecialties: [...action.value],
      };

    case actionType.GET_RESULT_SPECIALTIES:
      return {
        ...state,
        resultSpecialities: [...action.value],
        //Prevent "forgetting" new results (SpecialitiesResults shows only sortedSpecialities)
        resultSortSpecialities: [...action.value],
        specialities: [...action.array],
      };

    case actionType.CLEAR_RESULT_SPECIALITIES:
      return {
        ...state,
        resultSpecialities: [],
        resultSortSpecialities: [],
        specialities: [],
      };

    case actionType.SET_SPECIALTIES_BY_SORT:
      const specialities = [...state.resultSpecialities];
      const filter = action.filterValue.toLowerCase();
      const lang = state.language;

      const filterSpecialities = filter
          ? specialities.map((category) => ({
            ...category,
            specialities: category.specialities.filter((speciality) => {
              if (speciality.speciality_name[lang].toLowerCase().includes(filter)) {
                return true
              }
              if (speciality.proposition_name[lang].toLowerCase().includes(filter)) {
                return true
              }
              if (speciality.info[lang].toLowerCase().includes(filter)) {
                return true
              }
              /*return speciality.programs.some((program) => {
                return program.name[lang].toLowerCase().includes(filter)
                    || program.info[lang].toLowerCase().includes(filter);
              });*/
            }),
          }))
          : specialities;

      let sortSpecialities = [];
      switch (action.sortType) {
        case 'byCode':
          sortSpecialities = filterSpecialities.map((category) => ({
            ...category,
            specialities: category.specialities.sort((a, b) => a.code - b.code),
          }));
          break;
        case 'ascending':
          sortSpecialities = filterSpecialities.map((category) => ({
            ...category,
            specialities: category.specialities
                .sort((a, b) => a.pass_score - b.pass_score),
          }));
          break;
        case 'descending':
          sortSpecialities = filterSpecialities.map((category) => ({
            ...category,
            specialities: category.specialities
                .sort((a, b) => b.pass_score - a.pass_score),
          }));
          break;
        default:
          sortSpecialities = specialities;
      }

      return {
        ...state,
        resultSortSpecialities: sortSpecialities,
      };

    case actionType.ADD_TO_FAVOURITES:
      state.favourites[action.status].push({id_pro: action.id_pro})
      localStorage.setItem('favourites', JSON.stringify(state.favourites));

      return {
        ...state,
      };

    case actionType.SAVE_USER_CODE:
      localStorage.setItem('userCode', action.userCode);
      return {
        ...state,
        userCode: action.userCode,
      };

    default: return state;
  }
};

export default specialities;