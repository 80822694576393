import React from 'react';
import { connect } from 'react-redux';
import './SearchSelectSpeciality.scss';
import ErrorTooltip from "../Shared/ErrorTooltip/ErrorTooltip";
import DropDownBase from '../Shared/DropDownBase/DropDownBase';
import { specialities } from "../../assets/specialities";

class SearchSelectSpeciality extends DropDownBase {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      value: '',
      searchResults: specialities,
      classSelector: '.search-select',
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.setValue = this.setValue.bind(this);
    this.filterSpecialties = this.filterSpecialties.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  setValue(value) {
    const { selectSpecialty } = this.props;
    this.setState({value});
    selectSpecialty(value);
    this.closeMenu();
  }

  filterSpecialties(event) {
    const { value } = event.target;
    const { lang } = this.props;

    this.setState({
      value,
      searchResults: specialities.filter( speciality => (
          (speciality['code'] + ' - ' + speciality[lang]).toLowerCase()
              .includes(value.toLowerCase())
      )),
    });
  }

  render() {
    const { value, isOpenMenu, searchResults, isFocus } = this.state;
    const { label, isValid, error, lang, isLight }  = this.props;

    const menu = isOpenMenu ? (
        <ul className="search-select__menu">
          {
            searchResults.map((result, index) => (
                <li
                    key={index}
                    className="search-select__menu-item"
                    onClick={() => this.setValue(result['code'] + ' - ' + result[lang])}
                >
                  {result['code'] + ' - ' + result[lang]}
                </li>
            ))
          }
        </ul>
    ) : '';

    return (
        <div className={isLight
            ? 'search-select select--light'
            : 'search-select select--dark'
        }>
          <div
              className={'search-select__container'}
              onClick={this.openMenu}
          >
            <label
                className="search-select__label"
                style={{
                  display: (isFocus && value) ? 'none' : 'block',
                }}
            >
              {label}
            </label>
            <input
                id={label}
                value={value}
                onChange={this.filterSpecialties}
                className={isValid
                    ? 'search-select__input'
                    : 'search-select__input search-select__input--error'
                }
            />
            {isValid ? '' : <ErrorTooltip error={error} />}
          </div>
          {menu}
        </div>
    )
  }
}

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
  lang: state.setMainParams.language
});

export default connect(mapStateProps)(SearchSelectSpeciality);