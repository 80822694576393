import React from 'react';
import {connect} from 'react-redux';
import './CheckInput.scss';

const CheckInput = ({ isLight, input, label }) => (
  <div className="check-container">
    <input
      className="check__input"
      type="checkbox"
      {...input}
    />
    <label
      className="check__label"
      style={{ color: isLight ? '#494949' : '#ffffff'}}
    >
      {label}
    </label>
  </div>
);

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

export default connect(mapStateProps)(CheckInput);
