import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import arrowCode from '../../assets/images/prompt/Arrow-code.svg';
import arrowScore from '../../assets/images/prompt/ArrowScore.svg';
import arrowName from '../../assets/images/prompt/ArrowName.svg';
import arrowShortName from '../../assets/images/prompt/ArrowShortName.svg';
import arrowYear from '../../assets/images/prompt/ArrowYear.svg';
import SpecialityCard from "../SpecialitiesResult/SpecialityCard/SpecialityCard";
import arrowCodeLight from '../../assets/images/prompt/Arrow-code-light.svg';
import arrowScoreLight from '../../assets/images/prompt/ArrowScore-light.svg';
import arrowNameLight from '../../assets/images/prompt/ArrowName-light.svg';
import arrowShortNameLight from '../../assets/images/prompt/ArrowShortName-light.svg';
import arrowYearLight from '../../assets/images/prompt/ArrowYear-light.svg';

import './PromptWindow.scss';
import { specialityPrompt } from '../../assets/specialityPrompt';

const PromptWindow = ({ closeWindow, type, t, isLight }) => (
  <section className={isLight ? 'prompt prompt--light' : 'prompt'}>
    <h3 className="prompt__title">{t('prompt.title')}</h3>

    <div className="prompt__content">
      <div className="prompt__content-info">
        <div className="prompt__info prompt__info--code">
          <span className="prompt__info-label prompt__info-label--code">
            {t('card.code')}
          </span>
          <div className="prompt__arrow prompt__arrow--code">
            <img src={isLight ? arrowCodeLight : arrowCode} alt="arrow" />
          </div>
        </div>

        <div className="prompt__info prompt__info--name">
          <span className="prompt__info-label prompt__info-label--name">
            {t('prompt.name')}
          </span>
          <div className="prompt__arrow prompt__arrow--name">
            <img src={isLight ? arrowNameLight : arrowName} alt="arrow" />
          </div>
        </div>

        <div className="prompt__info prompt__info--score">
          <span className="prompt__info-label prompt__info-label--score">
            {t('card.score')}
          </span>
          <div className="prompt__arrow prompt__arrow--score">
            <img src={isLight ? arrowScoreLight : arrowScore} alt="arrow" />
          </div>
        </div>
      </div>

      <SpecialityCard
        extraClass="card--prompt"
        proposition={specialityPrompt}
        showDetails={() => {}}
      />

      <div className="prompt__content-info prompt__content-info--right">
        <div className="prompt__info prompt__info--short-name">
          <span className="prompt__info-label prompt__info-label--short-name">
            {t('card.shortName')}
          </span>
          <div className="prompt__arrow prompt__arrow--short-name">
            <img src={isLight ? arrowShortNameLight : arrowShortName} alt="arrow" />
          </div>
        </div>

        <div className="prompt__info prompt__info--year">
          <span className="prompt__info-label prompt__info-label--year">
            {t('card.passScore')}
          </span>
          <div className="prompt__arrow prompt__arrow--year">
            <img src={isLight ? arrowYearLight : arrowYear} alt="arrow" />
          </div>
        </div>
      </div>
    </div>

    <button
      className="prompt__button results__button"
      onClick={() => closeWindow(type)}
    >
      {t("prompt.button")}
    </button>
  </section>
);

const mapStateToProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

export default withTranslation()(connect(mapStateToProps)(PromptWindow));
