import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import DropDownBase from '../Shared/DropDownBase/DropDownBase';
import * as actionCreators from "../../store/action";
import './SortSelector.scss';

class SortSelector extends DropDownBase {
  constructor(props) {
    super(props);

    this.state = {
      classSelector: '.sort',
      sortType: 'byCode',
    };

    this.sortHandler = this.sortHandler.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  sortHandler(value) {
    const { filterValue, changeSortType } = this.props;
    this.setState({ sortType: value });
    changeSortType(value, filterValue);
    this.closeMenu();
  }

  render() {
    const { t, isLight } = this.props;
    const { isOpenMenu, sortType } = this.state;

    const options = ['byCode', 'ascending', 'descending'];
    const menu = isOpenMenu ? (
      <ul
        className="sort__menu"
        onChange={this.sortHandler}
      >
        {
          options.map((value, index) => (
            <li
              key={`${value}${index}`}
              onClick={() => this.sortHandler(value)}
              className="sort__menu-option sort__drop-down"
            >
              {t(`results.sort.${value}`)}
            </li>
          ))
        }
      </ul>
    ) : null;

    return (
      <div className={isLight ? 'sort sort--light': 'sort'}>
        {/*<span className="sort__label">
          {t('results.sort.label')}
        </span>*/}
        <div
          className="sort__value sort__drop-down"
          onClick={this.openMenu}
        >
          {t(`results.sort.${sortType}`)}
        </div>
        {menu}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

const mapDispatchToProps = (dispatch) => ({
  changeSortType: (typeSort, filterValue) => dispatch(actionCreators.changeSortType(typeSort, filterValue)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SortSelector));