import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SpecialityCard from './SpecialityCard/SpecialityCard';
import ModalWindow from '../Shared/ModalWindow/ModalWindow';
import DetailedCard from '../DetailedCard/DetailedCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../../store/action';
import './SpecialitiesResult.scss';
import PromptWindow from "../PromptWindow/PromptWindow";
import ModalWithoutClose from "../Shared/ModalWindow/ModalWithoutClose";
import SortSelector from "../SortSelector/SortSelector";
import APIService from "../../services/APIService";

class SpecialitiesResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDetails: false,
      isShowPrompt: false,
      proposition: '',
      sortBy: 'byCode',
      filterValue: '',
      favourites: this.props.favourites
    };
    this.showModalHandler = this.showModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.showPromptHandler = this.showPromptHandler.bind(this);
    this.showPromptHandler = this.showPromptHandler.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.addToFavouritesHandler = this.addToFavouritesHandler.bind(this);
    this.updateSpecialitiesResult = this.updateSpecialitiesResult.bind(this);
  }

  componentDidMount() {
    const { sortBy, filterValue } = this.state;
    this.props.changeSortType(sortBy, filterValue);
  }

  showModalHandler(proposition) {
    const { user_code, status } = this.props;
    APIService.sendLook({
      user_code, status, look: proposition.id_pro
    }).then(() => {});
    this.setState({ isShowDetails: true, proposition: proposition });
  }

  showPromptHandler() {
    this.setState({ isShowPrompt: true });
  }

  closeModalHandler(type) {
    this.setState({ [type]: false });
  }

  filterHandler(event) {
    const { value } = event.target;
    const { sortBy } = this.state;
    this.setState({ filterValue: value });
    this.props.changeSortType(sortBy, value);
  }

  clearFilter() {
    const value = '';
    const { sortBy } = this.state;
    this.setState({ filterValue: value });
    this.props.changeSortType(sortBy, value);
  }

  sortHandler(event) {
    const { value } = event.target;
    const { filterValue } = this.state;
    this.setState({ sortBy: value });
    this.props.changeSortType(value, filterValue);
  }

  addToFavouritesHandler(id_pro) {
    const { user_code, status, addToFavourites } = this.props;
    APIService.sendInterest({
      user_code, status, interest: id_pro
    }).then(() => {});
    addToFavourites(status, id_pro);
  }

  updateSpecialitiesResult() {
    this.setState({ favourites: localStorage.getItem('favourites')
          ? JSON.parse(localStorage.getItem('favourites'))
          : {'school_24': [], 'school': [], 'school_zno': [],
            'college_24': [], 'college': [], 'college_zno': [],
            'master': [], 'phd': []} })
  }

  render() {
    const { status, t, sortedSpecialities, isLight } = this.props;
    const { isShowDetails, proposition, favourites, isShowPrompt, filterValue } = this.state;
    const fa = (status in favourites) ? favourites[status] : favourites['school']

    const showDetails = isShowDetails ? (
        <ModalWindow
            show={isShowDetails}
            type="isShowDetails"
            closeWindow={this.closeModalHandler}
            closeByOutsideClick={true}
            extraClass="detailed">
          <DetailedCard
              proposition={proposition}
              update={this.updateSpecialitiesResult}
              interested={this.addToFavouritesHandler}/>
        </ModalWindow>
    ) : '';

    const showPrompt = isShowPrompt ? (
        <ModalWithoutClose
            show={isShowPrompt}>
          <PromptWindow
              closeWindow={this.closeModalHandler}
              type="isShowPrompt"/>
        </ModalWithoutClose>
    ) : '';

    return (
        <section
            id="specialities_results"
            className={isLight
                ? 'results results--light big-container'
                : 'results results--dark big-container'}
        >
          { showDetails }
          { showPrompt }
          <h2 className="results__title">
            {t('results.title')}
          </h2>

          <div className="results__filter">
            <FontAwesomeIcon icon={faSearch} />
            <input
                value={filterValue}
                type="text"
                className="results__filter-input"
                onChange={this.filterHandler}
                placeholder={t('results.filterPlaceholder')}
            />
            <div onClick={this.clearFilter} style={{ cursor: "pointer"}}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>

          <SortSelector filterValue={filterValue} />
          {
            (sortedSpecialities.reduce((num, cur) => {return cur.specialities.length + num}, 0) > 0)
                ? sortedSpecialities
                    .filter((item) => {return item.specialities.length > 0})
                    .map((item, index) => (
                        <section
                            className="results__specialties"
                            key={`${item.type}${index}`}
                        >
                          <div className="results__specialties-row">
                            <h3 className="results__specialties-title">
                              {t(`results.row.${item.type}`)}
                            </h3>
                            <button
                                className="results__specialties-button results__button"
                                onClick={this.showPromptHandler}
                            >
                              {t('results.row.button')}
                            </button>
                          </div>
                          <div className="specialties">
                            {
                              item.specialities.map((proposition, index) => (
                                  <SpecialityCard
                                      key={index*100}
                                      extraClass={`card--${item.type}`}
                                      proposition={proposition}
                                      favourite={fa.some((favourite) => favourite.id_pro === proposition.id_pro)}
                                      showDetails={this.showModalHandler}
                                  />
                              ))
                            }
                          </div>
                        </section>
                    ))
                : ''
          }
        </section>
    )
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities.resultSpecialities,
  sortedSpecialities: state.specialities.resultSortSpecialities,
  favourites: state.specialities.favourites,
  user_code: state.specialities.userCode,
  status: localStorage.getItem('status'),
  isLight: state.setMainParams.isLight
});

const mapDispatchToProps = (dispatch) => ({
  changeSortType: (typeSort, filterValue) => dispatch(actionCreators.changeSortType(typeSort, filterValue)),
  addToFavourites: (status, id_pro) => dispatch(actionCreators.addToFavourites(status, id_pro))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SpecialitiesResult));