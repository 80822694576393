import React from 'react';
import './ModalWindow.scss';

const ModalWithoutClose = ({ children, show }) => (
  <div className="backdrop">
    <div
      className="modal"
      style={{
        opacity: show ? '1' : '0',
      }}
    >
      {children}
    </div>
  </div>
);

export default ModalWithoutClose;
