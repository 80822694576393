import React from 'react';
import { withTranslation } from "react-i18next";
import { Field, reduxForm } from 'redux-form';
import ExamsInput from '../../Shared/ExamsInput/ExamsInput';
import Button from "../../Shared/Button/Button";
import SearchSelectSpeciality from "../../SearchSelectSpeciality/SearchSelectSpeciality";
import CheckInput from "../../Shared/CheckInput/CheckInput";

const labels = [
    'ukrainian',
    'ukrainian & literature',
    'math',
    'foreign',
    'physics',
    'biology',
    'history',
    'chemistry',
    'geography',
];

class FormCollegeZNO extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isValidForm: false,
            isNumberExams: false,
            inputValues: new Map(),
            speciality_college: '',
            isValidateSpecialty: true,
        };

        this.validate = this.validate.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.selectSpecialty = this.selectSpecialty.bind(this);
        this.validateSpecialty = this.validateSpecialty.bind(this);
        this.validateMarks = this.validateMarks.bind(this);
    }

    validate(...values) {
        const value = values[0];
        const name = values[3];
        const { t } = this.props;
        const { isNumberExams, inputValues } = this.state;
        let isError = false;
        let error;

        switch (name) {
            case 'ukrainian':
                if (value) {
                    if (!this.setValidateValue(value,100,200)) {
                        error = t('startForm.errorLabel');
                        isError = true;
                        inputValues.delete(name);
                    } else {
                        inputValues.set(name, value);
                    }
                } else {
                    error = t('startForm.moderateUkrError');
                    isError = true;
                    inputValues.delete(name);
                }
                break;
            /*case 'mean_score_college':
                if (!value) {
                    return t('startForm.validateForm');
                } else {
                    if (!this.setValidateValue(value,1,100)) {
                        return t('startForm.errorLabel');
                    }
                }
                break;*/
            default:
                if (value) {
                    if (!this.setValidateValue(value,100,200)) {
                        error = t('startForm.errorLabel');
                        isError = true;
                        inputValues.delete(name);
                    } else {
                        inputValues.set(name, value);
                    }
                }
        }

        if (isError) {
            return error;
        }

        if (!isNumberExams) {
            this.setState({
                isValidForm: true,
            });
        }

        if (inputValues.size >= 2) {
            this.setState({ isNumberExams: false });
        }
    }

    setValidateValue(value, min, max) {
        return (+value >= min && +value <= max);
    }

    validateSpecialty() {
        const { speciality_college } = this.state;
        this.setState({
            isValidateSpecialty: (speciality_college && speciality_college !== ''),
        });
    }

    validateMarks() {
        const { inputValues } = this.state;
        if (inputValues.size < 2)  {
            this.setState({ isNumberExams: true });
        }

        this.validateSpecialty()
    }

    onSubmitHandler(values) {
        const { isValidateSpecialty, isNumberExams, speciality_college } = this.state;
        const data = {
            subjects: labels
                .filter(label => label in values)
                .reduce((obj, label) => {
                    return {...obj, [label]: parseInt(values[label])};}, {}),
            add_info: {
                speciality_college: speciality_college,
                courses: false,
                /*mean_score_college: 0 parseFloat(values.mean_score_college)*/
            }
        }
        if (isValidateSpecialty && !isNumberExams) {
            this.props.getResultSpecialities(data);
        }
    }

    selectSpecialty(specialty) {
        this.setState({
            speciality_college: specialty.substr(0,3),
            isValidateSpecialty: true
        });
    }

    render () {
        const { t, handleSubmit } = this.props;
        const { isValidForm, isValidateSpecialty, isNumberExams, inputValues } = this.state;

        return (
            <form
                className="data-form"
                onSubmit={handleSubmit(this.onSubmitHandler)}
                autoComplete="off"
            >
                <div className="data-form__row data-form__row--title">
                    {t('startForm.exams_zno')}
                    <span className={isNumberExams ? 'data-form__error' : 'data-form__hidden'}>
                        {t('startForm.errorZNO')}
                    </span>
                </div>
                <div className="data-form__row data-form__row--exam">
                    {
                        labels.map((label) => (
                            <Field
                                key={label}
                                name={label}
                                component={ExamsInput}
                                disabled={inputValues.size === 4}
                                type="text"
                                label={t(`startForm.subjects.${label}`)}
                                validate={this.validate}
                            />
                        ))
                    }
                </div>

                <div className="data-form__row">
                    {/*<Field
                        label={t('startForm.meanScoreCollege')}
                        name="mean_score_college"
                        component={ExamsInput}
                        type="text"
                        placeholder={t('startForm.placeholderWorks')}
                        validate={this.validate}
                    />*/}
                    {/*<Field
                        label={t('startForm.courses')}
                        name="courses"
                        component={CheckInput}
                    />*/}
                    <SearchSelectSpeciality
                        label={t('startForm.specialityCollege')}
                        selectSpecialty={this.selectSpecialty}
                        isValid={isValidateSpecialty}
                        error={t('startForm.selectError')}
                    />
                </div>

                <div className="data-form__submit">
                    <Button
                        extraClass="data-form__button"
                        validate={this.validateMarks}
                        onSubmit={handleSubmit(this.onSubmitHandler)}
                        title={t('startForm.button')}
                    />
                    <span
                        style={{
                            display: isValidForm ? 'none' : 'block',
                        }}
                        className="data-form__submit-error"
                    >
                        {t('startForm.validateForm')}
                    </span>
                </div>
            </form>
        );
    }
}

FormCollegeZNO = reduxForm({
    form: 'formCollegeZNO',
})(FormCollegeZNO);

export default withTranslation()(FormCollegeZNO);