import initialState from '../state';
import * as actionType from '../action';

const setMainParams = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_COLOR_SCHEME:
      const date = new Date();
      const hour = date.getHours();

      return {
        ...state,
        isLight: (hour > 6 && hour < 18),
      };

    case actionType.SET_LANGUAGE:
      return {
        ...state,
        language: action.value,
      };
    default:
      return state;
  }
};

export default setMainParams;