import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './ModalWindow.scss';

class ModalWindow extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const { closeByOutsideClick } = this.props;
        if (closeByOutsideClick) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        const { closeByOutsideClick } = this.props;
        if (closeByOutsideClick) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            const { type, closeWindow} = this.props;
            closeWindow(type);
        }
    }

    render() {
        const { children, type, show, closeWindow, isLight, extraClass='' } = this.props;

        return <div className="backdrop">
            <div ref={this.wrapperRef} className={isLight
                ? `modal modal--light modal--${extraClass}`
                : `modal modal--dark modal--${extraClass}`}
                 style={{opacity: show ? '1' : '0',}}>
                <div className="modal__header">
                    <button
                        type="button"
                        className="modal__close"
                        onClick={() => closeWindow(type)}>
                        <FontAwesomeIcon
                            className="modal__close-font"
                            icon={faTimes}
                            size="2x"/>
                    </button>
                </div>
                <div className="modal__body">
                    {children}
                </div>
            </div>
        </div>
    }
}

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

export default connect(mapStateProps)(ModalWindow);