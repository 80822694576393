import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import FormSchool24 from './FormSchool/FormSchool24';
import FormSchool from './FormSchool/FormSchool';
import FormSchoolZNO from "./FormSchool/FormSchoolZNO";
import FormCollege24 from './FormCollege/FormCollege24';
import FormCollege from './FormCollege/FormCollege';
import FormCollegeZNO from './FormCollege/FormCollegeZNO';
import FormMaster from './FormMaster/FormMaster';
import FormPhD from './FormPhD/FormPhD';
import FormContacts from '../FormContacts/FormContacts';
import './StartForm.scss';
import * as actionCreators from '../../store/action';
import { BrowserRouter as Router, Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import ModalWindow from "../Shared/ModalWindow/ModalWindow";

const statusValues = [
  'school_24',
  'school',
  //'school_zno',
  'college_24',
  'college',
  'college_zno',
  'master',
  //'phd',
];

class StartForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingModal: false,
      status: localStorage.getItem('status') || 'school_24',
      user_code: localStorage.getItem('userCode') || '',
      origin: localStorage.getItem('origin') || '',
    };

    this.modalCloseHandler = this.modalCloseHandler.bind(this);
    this.modalShowHandler = this.modalShowHandler.bind(this);
    this.getResultSpecialities = this.getResultSpecialities.bind(this);
    this.getUserSpecialities = this.getUserSpecialities.bind(this);
    this.setStatusHandler = this.setStatusHandler.bind(this);
  }

  componentDidMount() {
    //this.getAllSpecialities();
    const status = localStorage.getItem('status');
    const origin = localStorage.getItem('origin');
    this.setState({ status, origin });
  }

  /*getAllSpecialities() {
    const { getSelectorSpecialities } = this.props;

    APIService.getSpecialities()
        .then((specialities) => {
          //getSelectorSpecialties(specialities);
        });
  }*/

  getResultSpecialities(data) {
    const { getSpecialities, saveUserCode } = this.props;
    const { status, user_code, origin } = this.state;
    const params = { status, user_code, origin, data }
    APIService.sendExamResults(params)
        .then(({ specialities, userCode }) => {
          getSpecialities(specialities, params);
          window.scrollTo({
            top: document.getElementById("specialities_results").offsetTop,
            behavior: 'smooth'
          });
          saveUserCode(userCode);
          this.setState({user_code: userCode})
        });
    //this.modalShowHandler();
  }

  getUserSpecialities(params) {
    const { getSpecialities } = this.props;
    APIService.getUserResults(params)
        .then(({ specialities }) => {
          getSpecialities(specialities, params)
          window.scrollTo({
            top: document.getElementById("specialities_results").offsetTop,
            behavior: 'smooth'
          });
        });
  }

  modalShowHandler() {
    this.setState({ isShowingModal: true });
  }

  modalCloseHandler() {
    this.setState({ isShowingModal: false });
  }

  setStatusHandler(status) {
    const { clearSpecialities } = this.props;
    localStorage.setItem('status', status);
    clearSpecialities();
    this.setState({ status });
  }

  setActiveForm() {
    const { status } = this.state;

    switch (status) {
      case 'school_24':
        return (
            <FormSchool24 getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'school':
        return (
            <FormSchool getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'school_zno':
        return (
            <FormSchoolZNO getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'college_24':
        return (
            <FormCollege24 getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'college':
        return (
            <FormCollege getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'college_zno':
        return (
            <FormCollegeZNO getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'master':
        return (
            <FormMaster getResultSpecialities={this.getResultSpecialities}/>
        );
      case 'phd':
        return (
            <FormPhD getResultSpecialities={this.getResultSpecialities}/>
        );
      default: return '';
    }
  }

  render() {
    const { isShowingModal, status } = this.state;
    const { t, isLight } = this.props;

    const showModal = isShowingModal ? (
        <ModalWindow
            closeWindow={this.modalCloseHandler}
            closeByOutsideClick={false}
            show={isShowingModal}>
          <FormContacts closeHandler={this.modalCloseHandler}/>
        </ModalWindow>
    ) : '';

    return (
        <>
          {showModal}
          <div className="home-page container page">
            <h1 className="page__title">
              {t('startForm.title')}
            </h1>
            <div className="home-page__content">
              <Router>
                <ul className="status-list">
                  <li className="status-list__title">{t('startForm.enrolleeForm.you')}</li>
                  {
                    statusValues.map((value, index) => (
                        <div key={index}
                             onClick={() => this.setStatusHandler(value)}>
                          <Link key={index} to={"/" + value + "/0"}
                                className={(status === statusValues[index])
                                    ? "status-list__item status-list__item--active"
                                    : "status-list__item"}
                                style={{ background: isLight ? '#2075F0' : '#ff5615', }}>
                            <li key={index}>
                              {t(`startForm.enrolleeForm.${value}`)}
                            </li>
                          </Link>
                        </div>
                    ))
                  }
                </ul>

                {/*<div className="mobile-status">
                  <span  className="mobile-status__title">
                    {t('startForm.enrolleeForm.you')}
                  </span>
                  <label className="mobile-status__label">
                    <select
                        className="mobile-status__select"
                        style={{backgroundColor: "white"}}
                        onChange={(event) => this.setStatusHandler(statusValues[event.target.value])}
                    >
                      {
                        statusValues.map((value, index) => (
                            <option className={(status === statusValues[index])
                                ? "status-list__item status-list__item--active"
                                : "status-list__item"}
                                    key={index}
                                    value={index}
                                    onClick={() => this.setStatusHandler(value)}>
                              {t(`startForm.enrolleeForm.${value}`)}
                            </option>
                        ))
                      }
                    </select>
                  </label>
                </div>*/}

                <Switch>
                  <Route path="/:status/:param">
                    <Status getSpecialities={this.getUserSpecialities}/>
                  </Route>
                  <Route path="/">
                    <Redirect to="/school_24/0"/>
                  </Route>
                </Switch>
                {this.setActiveForm()}
              </Router>
            </div>
          </div>
          <div className="container page">
          </div>
        </>
    );
  }
}

function Status({ getSpecialities }) {
  const { status, param } = useParams();
  localStorage.setItem('status', status);
  if (param.length === 8) {
    localStorage.setItem('origin', param);
  } else if (param.length === 32) {
    const params = {user_code: param, status, origin: localStorage.getItem('origin')};
    getSpecialities(params);
  }
  return <div />
}

const mapStateToProps = (state) => ({
  isLight: state.setMainParams.isLight,
  lang: state.setMainParams.language
});

const mapDispatchToProps = (dispatch) => ({
  //getSelectorSpecialities: (specialities) => dispatch(actionCreators.getSelectorSpecialities(specialities)),
  saveUserCode: (code) => dispatch(actionCreators.saveUserCode(code)),
  getSpecialities: (specialities, params) => dispatch(actionCreators.getResultSpecialities(specialities, params)),
  clearSpecialities: () => dispatch(actionCreators.clearResultSpecialities()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StartForm));