import axios from 'axios';
//import SpecialityToSelect from '../models/SpecialityToSelect';
//import Speciality from '../models/Speciality';
import Proposition from "../models/Proposition";

const apiHost = 'https://assistant.khai.edu'
//const apiHost = 'http://localhost:9999'

const axiosWithParams = axios.create({
    baseURL: apiHost + '/api/assistant/',
    headers: {
        'accept': 'application/json',
    },
});

const axiosWithBody = axios.create({
    baseURL: apiHost + '/api/assistant/',
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    },
});

const axiosContacts = axios.create({
    baseURL: apiHost + '/api/assistant/',
    headers: {
        'Cache-Control': 'no-cache',
        'Accept': '*/*',
    }
});

axiosWithParams.interceptors.request.use((config) => ({
    ...config,
    params: {
        ...config.params,
    },
}));

axiosWithBody.interceptors.request.use((config) => ({
    ...config,
    body: {
        ...config.data,
    },
}));

axiosContacts.interceptors.request.use((config) => ({
    ...config,
    body: {
        ...config.data,
    },
}));

const APIService = {
    /*getSpecialities() {
        return axiosWithParams.get('specialities')
            .then(({data}) => {
                let specialties = [];
                for (const key in data) {
                    specialties.push(new SpecialityToSelect(data[key], key));
                }
                return specialties;
            })
            .catch((error) => console.log(error));
    },*/

    sendTracking(params) {
        return axiosWithBody.post('tracking', JSON.stringify(params))
            .then(({ data }) => {
                return data
            })
    },

    sendExamResults(params) {
        return axiosWithBody.post('propositions', JSON.stringify(params))
            .then(({ data }) => {
                return ({
                    /** @var {{user_code, propositions}} data */
                    userCode: data.user_code,
                    specialities: Object.keys(data.propositions).map((id_pro) =>
                        new Proposition(data.propositions[id_pro])),
                })
            })
            .catch((error) => console.log(error));
    },

    getUserResults(params) {
        return axiosWithBody.post('user_propositions', JSON.stringify(params))
            .then(({ data }) => {
                return ({
                    specialities: Object.keys(data.propositions).map((code) =>
                        new Proposition(data.propositions[code]))
                })
            })
            .catch((error) => console.log(error));
    },

    /*async sendAsync(contacts) {
        const response = await fetch('http://localhost:8080/assistant_requests.php', {
            method: 'post',
            headers: {
                'Cache-Control': 'no-cache',
                'Accept': '*!/!*',
            },
            params: {

            }
        });
    },*/

    async sendContacts(contacts) {
        return axiosWithBody.post('contacts', JSON.stringify(contacts))
            .then(({ data }) => { return data })
    },

    async sendInterest(interest) {
        return axiosWithBody.post('interest', JSON.stringify(interest))
            .then(({ data }) => { return data })
    },

    async sendLook(look) {
        return axiosWithBody.post('look', JSON.stringify(look))
            .then(({ data }) => { return data })
    },
};

export default APIService;