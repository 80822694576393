import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import ExamsInput from '../../Shared/ExamsInput/ExamsInput';
import Button from '../../Shared/Button/Button';
import SearchSelectSpeciality from "../../SearchSelectSpeciality/SearchSelectSpeciality";

class FormMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      speciality_bachelor: '',
      isValidateSpecialty: true,
    };

    this.validate = this.validate.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.selectSpecialty = this.selectSpecialty.bind(this);
    this.validateSpecialty = this.validateSpecialty.bind(this);
  }

  validate(...values) {
    const value = values[0];
    const name = values[3];
    const { t } = this.props;

    switch (name) {
      case 'foreign':
        if (!value) {
          return t('startForm.validateForm');
        } else {
          if (!this.setValidateValue(value,100,200)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      case 'mean_score_bachelor':
        if (!value) {
          return t('startForm.validateForm');
        } else {
          if (!this.setValidateValue(value,60,100)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      case 'student_wins':
        if (value) {
          if (!this.setValidateValue(value,0,30)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      case 'scientific_papers':
        if (value) {
          if (!this.setValidateValue(value,0,50)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      default:
    }
  }

  setValidateValue(value, min, max) {
    return (+value >= min && +value <= max);
  }

  validateSpecialty() {
    const { speciality_bachelor } = this.state;
    this.setState({
      isValidateSpecialty: (speciality_bachelor && speciality_bachelor !== ''),
    });
  }

  onSubmitHandler(values) {
    const { isValidateSpecialty, speciality_bachelor } = this.state;
    const data = {
      subjects: {
        /*foreign: parseInt(values['foreign'])*/
      },
      add_info: {
        speciality_bachelor: speciality_bachelor,
        /*mean_score_bachelor: parseFloat(values.mean_score_bachelor),*/
        /*student_wins: values.student_wins ? parseInt(values.student_wins) : 0,
        scientific_papers: values.scientific_papers ? parseInt(values.scientific_papers) : 0*/
      },
      /*mural: false*/
    }
    if (isValidateSpecialty) {
      this.props.getResultSpecialities(data);
    }
  }

  selectSpecialty(specialty) {
    this.setState({
      speciality_bachelor: specialty.substr(0,3),
      isValidateSpecialty: true
    });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const { isValidateSpecialty } = this.state;

    return (
        <form
            className="data-form"
            onSubmit={handleSubmit(this.onSubmitHandler)}
            autoComplete="off"
        >
          <div className="data-form__row data-form__row--title">
            {t('startForm.your_info')}
          </div>
          {/*<div className="data-form__row data-form__row--exam">
            <Field
                label={t('startForm.subjects.foreign')}
                name="foreign"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.subjects.placeholder')}
                validate={this.validate}
            />
          </div>*/}

          <div className="data-form__row">
            {/*<Field
                label={t('startForm.meanScoreBachelor')}
                name="mean_score_bachelor"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />*/}
            {/*<Field
                label={t('startForm.studentWins')}
                name="student_wins"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />
            <Field
                label={t('startForm.scientificPapers')}
                name="scientific_papers"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />*/}
            <SearchSelectSpeciality
                label={t('startForm.specialityBachelor')}
                selectSpecialty={this.selectSpecialty}
                isValid={isValidateSpecialty}
                error={t('startForm.selectError')}
            />
          </div>

          <div className="data-form__submit">
            <Button
                extraClass="data-form__button"
                onSubmit={handleSubmit(this.onSubmitHandler)}
                validate={this.validateSpecialty}
                title={t('startForm.load_button')}
            />
          </div>
        </form>
    );
  }
}

FormMaster = reduxForm({
  form: 'formMaster',
})(FormMaster);

export default withTranslation()(FormMaster);