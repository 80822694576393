import React from 'react';
import ErrorTooltip from '../ErrorTooltip/ErrorTooltip';
import './SelectPlace.scss';

const SelectPlace = ({ input, children, meta: { touched, error } }) => (
  <div className="select-container">
    <label className="select__label">
      <select
        className={touched && error
          ? 'select__place select__place--error'
          : 'select__place'}
        {...input}
      >
        {children}
      </select>
    </label>
    {
      touched
      && error
      && <ErrorTooltip error={error} />
    }
  </div>
);

export default SelectPlace;