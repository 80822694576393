import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from "redux-form";
import ExamsInput from '../../Shared/ExamsInput/ExamsInput';
import SelectPlace from '../../Shared/SelectPlace/SelectPlace';
import Button from '../../Shared/Button/Button';
import SearchSelectSpeciality from "../../SearchSelectSpeciality/SearchSelectSpeciality";

class FormPhD extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialty_master: '',
      isValidateSpecialty: true,
    };

    this.validate = this.validate.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.selectSpecialty = this.selectSpecialty.bind(this);
    this.validateSpecialty = this.validateSpecialty.bind(this);
  }

  validate(...values) {
    const value = values[0];
    const name = values[3];
    const { t } = this.props;

    switch (name) {
      case 'mean_score_master':
        if (!value) {
          return t('startForm.validateForm');
        } else {
          if (!this.setValidateValue(value,60,100)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      case 'scientific_papers':
        if (value) {
          if (!this.setValidateValue(value,0,100)) {
            return t('startForm.errorLabel');
          }
        }
        break;
      case 'level_CEFR' :
        if (value) {
          return (value) ? undefined : t('startForm.selectError');
        }
        break;
      default:
    }
  }

  setValidateValue(value, min, max) {
    return (+value >= min && +value <= max);
  }

  onSubmitHandler(values) {
    const { isValidateSpecialty, speciality_master } = this.state;
    const data = {
      add_info: {
        speciality_master: speciality_master,
        mean_score_master: parseFloat(values.mean_score_master),
        scientific_papers: values.scientific_papers ? parseInt(values.scientific_papers) : 0,
        level_CEFR: values.level_CEFR
      },
      mural: false
    }
    if (isValidateSpecialty) {
      this.props.getResultSpecialities(data);
    }
  }

  selectSpecialty(specialty) {
    this.setState({
      speciality_master: specialty.substr(0,3),
      isValidateSpecialty: true
    });
  }

  validateSpecialty() {
    const { speciality_master } = this.state;
    this.setState({
      isValidateSpecialty: (speciality_master && speciality_master !== ''),
    });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const { isValidateSpecialty } = this.state;

    return (
        <form
            className="data-form"
            onSubmit={handleSubmit(this.onSubmitHandler)}
            autoComplete="off"
        >
          <div className="data-form__row data-form__row--title">
            {t('startForm.your_info')}
          </div>

          <div className="data-form__row">
            <Field
                label={t('startForm.meanScoreMaster')}
                name="mean_score_master"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />
            <Field
                label={t('startForm.scientificPapers')}
                name="scientific_papers"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />
            <Field
                name="level_CEFR"
                component={SelectPlace}
                validate={this.validate}
            >
              <option value="">{t('startForm.levelCEFR')}</option>
              <option value="A1">A1</option>
              <option value="A2">A2</option>
              <option value="B1">B1</option>
              <option value="B2">B2</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
            </Field>
            <SearchSelectSpeciality
                label={t('startForm.specialityMaster')}
                selectSpecialty={this.selectSpecialty}
                isValid={isValidateSpecialty}
                error={t('startForm.selectError')}
            />
          </div>

          <div className="data-form__submit">
            <Button
                extraClass="data-form__button"
                onSubmit={handleSubmit(this.onSubmitHandler)}
                validate={this.validateSpecialty}
                title={t('startForm.button')}
            />
          </div>
        </form>
    );
  }
}

FormPhD = reduxForm({
  form: 'formPhD',
})(FormPhD);

export default withTranslation()(FormPhD);