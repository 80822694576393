export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME';
export const GET_SELECTOR_SPECIALTIES = 'GET_SELECTOR_SPECIALTIES';
export const GET_RESULT_SPECIALTIES = 'GET_RESULT';
export const CLEAR_RESULT_SPECIALITIES = 'CLEAR_RESULT_SPECIALITIES';
export const SET_SPECIALTIES_BY_SORT = 'SET_SPECIALTIES_BY_SORT';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES';
export const SAVE_USER_CODE = 'SAVE_USER_CODE';

export const setColorScheme = () => ({
  type: SET_COLOR_SCHEME,
});

export const getResultSpecialities = (array, params) => {
  const budgets = ((status) => {
    switch (status) {
      case 'school_24':
      case 'school':
      case 'school_zno':
        return array.filter((proposition) =>
            proposition.your_score >= proposition.pass_score
            && proposition.pass_score > 0)
      case 'college_24':
      case 'college':
      case 'college_zno':
        return array.filter((proposition) => ((proposition.support === 'AVAILABLE'
            || proposition.code.includes(params.data.add_info.speciality_college.substr(0,2)))
            && proposition.your_score >= proposition.min_score));
      case 'master':
        return array.filter((proposition) => !(params.data.add_info.speciality_bachelor !== '081'
            && proposition.code === '081'));
      default:
        return array;
    }
  })(params.status);

  const contracts = ((status) => {
    switch (status) {
      case 'school_24':
      case 'school':
      case 'school_zno':
        return (array.filter((proposition) =>
            proposition.your_score < proposition.pass_score
            || proposition.pass_score === 0))
            .filter((proposition) => !(proposition.your_score < proposition.min_score
                && ['081','281','292'].includes(proposition.code)));
      case 'college_24':
      case 'college':
      case 'college_zno':
        return array.filter((proposition) => ((proposition.support !== 'AVAILABLE'
            && !proposition.code.includes(params.data.add_info.speciality_college.substr(0,2)))
            || proposition.your_score <= proposition.min_score));
      case 'master':
        return array.filter((proposition) => (params.data.add_info.speciality_bachelor !== '081'
            && proposition.code === '081'));
      default:
        return [];
    }
  })(params.status);

  return ({
    type: GET_RESULT_SPECIALTIES,
    array,
    value: [
      {
        type: 'budget',
        specialities: [...budgets],
      },
      {
        type: 'contract',
        specialities: [...contracts],
      }
    ],
  });
};

export const clearResultSpecialities = () => {
  return ({
    type: CLEAR_RESULT_SPECIALITIES,
  });
}

export const getSelectorSpecialities = (value) => {
  return ({
    type: GET_SELECTOR_SPECIALTIES,
    value,
  })};

export const changeSortType = (sortType, filterValue) => ({
  type: SET_SPECIALTIES_BY_SORT,
  sortType,
  filterValue
});

export const setLanguage = (value) => {
  let lang;
  switch (value) {
    case 'ua':
      lang = 'ukr';
      break;
    case 'ru':
      lang = 'rus';
      break;
    case 'en':
      lang = 'eng';
      break;
    default:
      lang = 'ukr';
  }

  localStorage.setItem('lang', lang);
  return {
    type: SET_LANGUAGE,
    value: lang,
  }
};

export const addToFavourites = (status, id_pro) => ({
  type: ADD_TO_FAVOURITES,
  status,
  id_pro
});

export const saveUserCode = (userCode) => ({
  type: SAVE_USER_CODE,
  userCode,
});