export const campaignStages = {
    school_24: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    school: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    school_zno: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    college_24: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    college: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    college_zno: [
        {step: 'first', date: '01.07-31.10'},
        {step: 'second', date: '03.07-31.07'},
        {step: 'third', date: '19.07-31.07'},
        {step: 'fourth', date: '05.08 12:00'},
        {step: 'fifth', date: '08.08 18:00'},
        {step: 'sixth', date: '10.08 18:00'},
        {step: 'seventh', date: '17.08 18:00'},
        {step: 'eighth', date: '30.08 18:00'},
    ],
    master: [
        {step: 'first', date: '08.05 08:00'},
        {step: 'second', date: '31.05 18:00'},
        {step: 'third', date: '23.06-21.07'},
        {step: 'fourth', date: '01.07-30.11'},
        {step: 'fifth', date: '03.07 08:00'},
        {step: 'sixth', date: '26.07 18:00'},
        {step: 'seventh', date: '17.07-28.07'},
        {step: 'eighth', date: '26.08 18:00'},
        {step: 'ninth', date: '29.08 18:00'},
        {step: 'tenth', date: '31.08 18:00'},
        {step: 'eleventh', date: '08.09 18:00'},
        {step: 'twelfth', date: '30.09 18:00'},
    ],
    phd: [
        {step: 'first', date: '20.08-21.08'},
        {step: 'second', date: '20.08-21.08'},
        {step: 'third', date: '26.08-28.08'},
        {step: 'fourth', date: '31.08 18:00'},
    ]
};