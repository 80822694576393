import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './SpecialityCard.scss';

class SpecialityCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { proposition, favourite, showDetails, extraClass='', t, isLight, lang, status } = this.props;

    return /*<div className="card-link">*/<a className={
      isLight
          ? `card ${extraClass} card--light`
          : `card ${extraClass} card--dark`}
              onClick={() => showDetails(proposition)}
              style={{ background: favourite ? '#0BA960' : '', }}>
      <div className="card__row">
        <div className="card__row-info card__row-code">
          {proposition.code}
          <span className="card__tooltip">
            {t('card.code') + ' "' + proposition.speciality_name[lang] + '"'}
          </span>
        </div>
        <div className="card__row-info card__row-info--short card__row-info--code">
          {proposition.speciality_short_name[lang]}
          <span className="card__tooltip card__tooltip--right">
            {proposition.speciality_name[lang]}
          </span>
        </div>
      </div>
      <div className="card__row card__row--name">
        <p className="name">{proposition.proposition_name[lang]
          + (['JUN_BACHELOR'].includes(proposition.level)
            ? ' (' + t('detailed.junior_bachelor') + ')' : '')}</p>
        <p className="message">{t('card.message')}</p>
      </div>
      {
        ['school_24','school','school_zno','college_24','college','college_zno'].includes(status) ?
            <div className="card__row">
              <div className="card__row-info card__row-info--code">
                {proposition.your_score}
                <span className="card__tooltip card__tooltip--bottom">
                  {t('card.score')}
                </span>
              </div>
              <div className="card__row-info card__row-info--code">
                {proposition.pass_score > 0 ? '[' + proposition.pass_score + ']' : ''}
                <span className="card__tooltip card__tooltip--bottom card__tooltip--right">
                  {t('card.passScore')}
                </span>
              </div>
            </div>
            : [''].includes(status) ?
                <div className="card__row">
                  <div className="card__row-info card__row-info--code">
                    {proposition.your_score}
                    <span className="card__tooltip card__tooltip--bottom">
                      {t('card.score')}
                    </span>
                  </div>
                  <div className="card__row-info card__row-info--code">
                    {''}
                    <span className="card__tooltip card__tooltip--bottom card__tooltip--right">
                  {t('card.passScore')}
                </span>
                  </div>
                </div>
                : <div className="card__row"/>
      }
      {/*<span className="card__tooltip">
        {t('card.message')}
      </span>*/}
    </a>
    /*</div>*/
  }
}

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
  lang: state.setMainParams.language,
  status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateProps)(SpecialityCard));