import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import ExamsInput from '../../Shared/ExamsInput/ExamsInput';
/*import SelectPlace from "../../Shared/SelectPlace/SelectPlace";*/
import CheckInput from "../../Shared/CheckInput/CheckInput";
import Button from "../../Shared/Button/Button";

const labels = [
  'ukrainian',
  'math',
  'foreign',
  'physics',
  'biology',
  'history',
  'chemistry'
];

class FormSchool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidForm: false,
      isNumberExams: false,
      inputValues: new Map()
    };

    this.validate = this.validate.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.validateMarks = this.validateMarks.bind(this);
  }

  validate(...values) {
    const value = values[0];
    const name = values[3];
    const { t } = this.props;
    const { isNumberExams, inputValues } = this.state;
    let isError = false;
    let error;

    switch (name) {
      /*case 'olympiad':
        if (value) {
          if (!this.setValidateValue(value,0,20)) {
            error = t('startForm.errorLabel');
            isError = true;
          }
        }
        break;
      case 'certificate':
        if (!(value && this.setValidateValue(value,1,12))) {
          error = t('startForm.errorLabel');
          isError = true;
        }
        break;
      case 'address':
        if (!value) {
          error = t('startForm.selectError');
          isError = true;
        }
        break;*/
      case 'ukrainian':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,5,45)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        } else {
          error = t('startForm.moderateUkrError');
          isError = true;
          inputValues.delete(name);
        }
        break;
      case 'math':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,32)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        } else {
          error = t('startForm.moderateMathError');
          isError = true;
          inputValues.delete(name);
        }
        break;
      case 'history':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,6,54)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'biology':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,6,46)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'chemistry':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,40)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'foreign':
      case 'physics':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,32)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      default:
        if (value) {
          if (!this.setValidateValue(value,100,200)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
    }

    if (isError) {
      return error;
    }

    if (!isNumberExams) {
      this.setState({
        isValidForm: true,
      });
    }

    //if (inputValues.size === 3 || inputValues.size === 4) {
    if (inputValues.size >= 3) {
      this.setState({ isNumberExams: false });
    }
  }

  setValidateValue(value, min, max) {
    return (+value >= min && +value <= max);
  }

  validateMarks() {
    //if (inputValues.size !== 3 && inputValues.size !== 4) {
    const { inputValues } = this.state;
    if (inputValues.size < 3) {
      this.setState({ isNumberExams: true });
    }
  }

  onSubmitHandler(values) {
    const { isNumberExams } = this.state;
    const data = {
      subjects: labels
          .filter(label => label in values)
          .reduce((obj, label) => {
            return {...obj, [label]: parseInt(values[label])};}, {}),
      add_info: {
        /*olympiad: values.olympiad ? parseInt(values.olympiad) : 0,
        certificate: parseFloat(values.certificate),*/
        courses: values.courses === true,
        /*address: values.address*/
      }
    }
    if (!isNumberExams) {
      this.props.getResultSpecialities(data);
    }
  }

  render() {
    const { t, handleSubmit } = this.props;
    const { isValidForm, isNumberExams, inputValues } = this.state;

    return (
        <form
            className="data-form"
            onSubmit={handleSubmit(this.onSubmitHandler)}
            autoComplete="off"
        >
          <div className="data-form__row data-form__row--title">
            {t('startForm.exams')}
            <span className={isNumberExams ? 'data-form__error' : 'data-form__hidden'}>
              {t('startForm.errorNMT')}
            </span>
          </div>

          <div className="data-form__row data-form__row--exam">
            {
              labels.map((label) => (
                  <Field
                      key={label}
                      name={label}
                      component={ExamsInput}
                      disabled={inputValues.size === 3}
                      type="text"
                      label={t(`startForm.subjects.${label}`)}
                      validate={this.validate}
                  />
              ))
            }
          </div>

          <div className="data-form__row">
            {/*<Field
                name="olympiad"
                component={ExamsInput}
                type="text"
                label={t('startForm.olympiad')}
                validate={this.validate}
            />
            <Field
                name="certificate"
                component={ExamsInput}
                type="text"
                label={t('startForm.certificate')}
                validate={this.validate}
            />
            <Field
                name="address"
                component={SelectPlace}
                validate={this.validate}
            >
              <option value="">{t('startForm.placeFrom.choice')}</option>
              <option value="CITY">{t('startForm.placeFrom.city')}</option>
              <option value="COUNTRYSIDE">{t('startForm.placeFrom.village')}</option>
            </Field>*/}
            <Field
                label={t('startForm.courses')}
                name="courses"
                component={CheckInput}
            />
          </div>

          <div className="data-form__submit">
            <Button
                validate={this.validateMarks}
                onSubmit={handleSubmit(this.onSubmitHandler)}
                title={t('startForm.button')}
            />
            <span
                style={{
                  display: isValidForm ? 'none' : 'block',
                }}
                className="data-form__submit-error"
            >
              {t('startForm.validateForm')}
            </span>
          </div>
        </form>
    );
  }
}

FormSchool = reduxForm({
  form: 'formSchool',
})(FormSchool);

export default withTranslation()(FormSchool);