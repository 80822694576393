import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers} from 'redux';
import './i18n';
import { reducer as formReducer } from 'redux-form';
import setMainParams from './store/reducers/setMainParams';
import specialities from './store/reducers/specialities';
import App from './App';
import './styles/index.scss';

const reducers = { form: formReducer, setMainParams, specialities: specialities };
const reducer = combineReducers(reducers);
let store = createStore(reducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
