import React from 'react';
import ErrorTooltip from '../ErrorTooltip/ErrorTooltip';
import Radium from "radium";
import './ExamsInput.scss';

const ExamsInput = ({label, input, type, disabled, meta: { touched, error }}) => (
    <div className="input-container">
        {/*<label htmlFor={label} className="input__label">{label}</label>*/}
        <input
            id={label}
            className={touched && error
                ? 'input__value input__value--error'
                : 'input__value'}
            {...input}
            disabled={disabled && !input.value}
            type={type}
            placeholder={label}
            maxLength="5"
            autoComplete="false"
            onClick={(event) => event.preventDefault()}
        />
        <label className="input-label">{label}</label>
        {
            touched
            && error
            && <ErrorTooltip error={error} />
        }
    </div>
);

export default Radium(ExamsInput);
