export default class Proposition {
    constructor(data) {
        this.id_spec = data.id_spec;
        this.id_pro = data.id_pro;
        this.dept = data.dept;
        this.level = data.level;
        this.code = data.code;
        this.speciality_name = {
            ukr: data.speciality_name.ukr ? data.speciality_name.ukr : '',
            rus: data.speciality_name.rus ? data.speciality_name.rus : '',
            eng: data.speciality_name.eng ? data.speciality_name.eng : '',
        };
        this.speciality_short_name = {
            ukr: data.speciality_short_name.ukr ? data.speciality_short_name.ukr : '',
            rus: data.speciality_short_name.rus ? data.speciality_short_name.rus : '',
            eng: data.speciality_short_name.eng ? data.speciality_short_name.eng : '',
        };
        this.pass_score = data.pass_score;
        this.pass_scores = data.pass_scores;
        this.min_score = data.min_score;
        this.your_score = data.your_score;
        this.support = data.support;
        this.proposition_name = {
            ukr: data.proposition_name.ukr ? data.proposition_name.ukr : '',
            rus: data.proposition_name.rus ? data.proposition_name.rus : '',
            eng: data.proposition_name.eng ? data.proposition_name.eng : '',
        };
        this.links = data.links ? {
            department: data.links.department
                ? data.links.department : null,
            curriculum: data.links.curriculum
                ? data.links.curriculum : null,
            curriculum_college: data.links.curriculum_college
                ? data.links.curriculum_college : null,
            edu_program: data.links.edu_program
                ? data.links.edu_program : null,
            telegram: data.links.telegram
                ? data.links.telegram : null,
            test_college: data.links.test_college
                ? data.links.test_college : null,
            youtube: data.links.youtube
                ? data.links.youtube : null
        } : {
            site: '',
            edu_program: '',
            telegram: ''
        };
        this.info = data.info ? {
            ukr: data.info.ukr ? data.info.ukr : '',
            rus: data.info.rus ? data.info.rus : '',
            eng: data.info.eng ? data.info.eng : '',
        } : {
            ukr: '',
            rus: '',
            eng: '',
        };
        this.details = data.details ? {

        } : {

        };
    }
}
