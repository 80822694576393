export const specialityPrompt = {
  code: 999,
  speciality_name: {
    ukr: 'Скорочена назва спеціальності',
    rus: 'Сокращенное название специальности',
    eng: 'Short name of speciality'
  },
  speciality_short_name: {
    ukr: 'CНС',
    rus: 'CНС',
    eng: 'SNS'
  },
  proposition_name: {
    ukr: 'Спеціальність вашої мрії',
    rus: 'Специальность вашей мечты',
    eng: 'Speciality of your dream'
  },
  pass_score: 171.146,
  your_score: 179.523,
  year: 2022
};