import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import StartForm from './components/StartForm/StartForm';
import './styles/App.scss';
import SpecialitiesResult from './components/SpecialitiesResult/SpecialitiesResult';
import FormContacts from './components/FormContacts/FormContacts';
import * as actionCreators from './store/action';
import StepsLanding from './components/StepsLanding/StepsLanding';
import telegramLogo from './assets/images/Telegram_logo.png';

const App = ({ resultSpecialities, setColorScheme, isLight, t }) => {
  setColorScheme();
  return (
    <Suspense fallback={(<div>Loading</div>)}>
      <div className="App">
        <main
          className={isLight
            ? 'App__main App__main--light'
            : 'App__main App__main--dark'}
        >
          <Header/>
          <StartForm />
          {
            resultSpecialities.length > 0 ? <SpecialitiesResult /> : null
          }
          <section className="App__contacts big-container">
            <FormContacts closeHandler={() => {}}/>
          </section>
          <StepsLanding />
        </main>
        <Footer/>
        {/*<a*/}
        {/*  href="https://t.me/assistant_khai_bot"*/}
        {/*  target="_blank"*/}
        {/*  className="telegram"*/}
        {/*>*/}
        {/*  <span className="telegram__title">*/}
        {/*    {t('footer.telegram_bot')}*/}
        {/*  </span>*/}
        {/*  <div className="telegram__icon">*/}
        {/*    <img src={telegramLogo} alt="telegram"/>*/}
        {/*  </div>*/}
        {/*</a>*/}
      </div>
    </Suspense>
  );
};

const mapStateProps = (state) => ({
  resultSpecialities: state.specialities.resultSpecialities,
  isLight: state.setMainParams.isLight,
});

const mapDispatchToProps = (dispatch) => ({
  setColorScheme: () => dispatch(actionCreators.setColorScheme()),
});

export default withTranslation()(connect(mapStateProps, mapDispatchToProps)(App));