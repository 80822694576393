import React from 'react';
import './Header.scss';
import logo from '../../assets/images/logo_white.png';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import langIcon from '../../assets/images/vectorWorld.svg';
import * as actionCreators from "../../store/action";

const Header = ({ t, isLight, setLanguage }) => {
  const { i18n } = useTranslation();
  const mainLang =  t('languages.main').toUpperCase();
  const languages = [t('languages.item1'), t('languages.item2')];

  return (
    <header className="header big-container" id="top">
      <div className="header__container">
        <a href="/" className="header__logo">
          <img src={logo} alt="logo" />
        </a>
        <button
            className={isLight
                ? 'btn-top btn-top-light'
                : 'btn-top btn-top-dark'}
            onClick={() => {window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })}}/>
        <div className="header__language">
          <div className="header__language-main">
            <span>{mainLang}</span>
            <img src={langIcon} alt="language"/>
          </div>
          <div className={isLight
              ? 'header__language-list list-light'
              : 'header__language-list list-dark'
            }
          >
            {
              languages.map((item) => (
                <span
                  key={item}
                  className="header__language-list__item"
                  onClick={() => {
                    i18n.changeLanguage(item);
                    setLanguage(item);
                  }}
                >
                  {item.toUpperCase()}
                </span>
              ))
            }
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(actionCreators.setLanguage(lang)),
});

export default withTranslation()(connect(mapStateProps, mapDispatchToProps)(Header));
