import React from 'react';
import './ErrorTooltip.scss';

const ErrorTooltip = ({ error }) => (
  <div className="error-tooltip">
    <span className="error-tooltip__description">
      {error}
    </span>
  </div>
);

export default ErrorTooltip;
