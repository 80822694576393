import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ContactInput from './ContactInput/ContactInput';
import Button from '../Shared/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faPaperPlane, faPhone} from '@fortawesome/free-solid-svg-icons';
import './FormContacts.scss';
import APIService from "../../services/APIService";

class FormContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactsSent: false
    }

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onSubmitHandler(values) {
    const { closeHandler } = this.props;
    const contacts = { ...values,
      user_code: localStorage.getItem('userCode') };
    APIService.sendContacts(contacts)
        .then(() => { this.setState({ contactsSent: true }) });
    closeHandler();
  }

  validate = (...values) => {
    const value = values[0];
    const name = values[3];
    const { t } = this.props;

    let isError = false;
    let error;
    switch (name) {
      case 'full_name':
        if (!value) {
          error = t(`contactForm.errors.${name}`);
          isError = true;
        }
        break;
      case 'phone':
        if (!value || !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i.test(value)) {
          error = t(`contactForm.errors.${name}`);
          isError = true;
        }
        break;
      case 'email':
        if (!value || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i.test(value)) {
          error = t(`contactForm.errors.${name}`);
          isError = true;
        }
        break;
      default: error = undefined;
    }

    if (isError) {
      return error;
    }
  };

  render() {
    const { t, handleSubmit, isLight } = this.props;
    const { contactsSent } = this.state;

    return (
        <div
            className={isLight
                ? 'contacts contacts--light'
                : 'contacts contacts--dark'
            }
        >
          <div className="contacts__description">
            <article>
              <h3 className="contacts__description-title">
                {t('contactForm.article.title')}
              </h3>
              <p className="contacts__description-info">
                {t('contactForm.article.info')}
                {/*{t('contactForm.article.contest')}*/}
              </p>
              {/*<a*/}
              {/*    href="https://t.me/assistant_khai_bot"*/}
              {/*    target="_blank"*/}
              {/*    className="contacts__description-telegram"*/}
              {/*    onClick={() => {*/}
              {/*      window.open("https://t.me/assistant_khai_bot?start="*/}
              {/*          + localStorage.getItem('userCode'))}}*/}
              {/*>*/}
              {/*  {t('footer.telegram')}*/}
              {/*  <FontAwesomeIcon*/}
              {/*      style={{ marginLeft: '10px'}}*/}
              {/*      icon={faPaperPlane}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*<p></p>*/}
              {/*<a*/}
              {/*    href="https://t.me/vstup_khai"*/}
              {/*    target="_blank"*/}
              {/*    className="contacts__description-telegram"*/}
              {/*>*/}
              {/*  {t('footer.telegram_channel')}*/}
              {/*  <FontAwesomeIcon*/}
              {/*      style={{ marginLeft: '10px'}}*/}
              {/*      icon={faPaperPlane}*/}
              {/*  />*/}
              {/*</a>*/}
              {/*<p></p>*/}
              <a
                  href="https://khai.edu/ua/abiturientu/konsultacijnij-centr-hai/"
                  target="_blank"
                  className="contacts__description-telegram"
              >
                {t('footer.consultation_center')}
                <FontAwesomeIcon
                    style={{ marginLeft: '10px'}}
                    icon={faPhone}
                />
              </a>
            </article>
          </div>
          {
            contactsSent
                ? <h3 className="contacts-sent">
                  <FontAwesomeIcon
                      style={{marginRight: '10px'}}
                      icon={faCheck}/>
                  {t('contactForm.contactsSent')}
                </h3>
                : <form
                    className="form-contacts"
                    onSubmit={handleSubmit(this.onSubmitHandler)}
                    autoComplete="off"
                >
                  <Field
                      name="full_name"
                      component={ContactInput}
                      type="text"
                      label={t('contactForm.full_name')}
                      placeholder=""
                      validate={this.validate}
                  />
                  <Field
                      name="phone"
                      component={ContactInput}
                      label={t('contactForm.phone')}
                      type="text"
                      placeholder="+380..."
                      validate={this.validate}
                  />
                  <Field
                      name="email"
                      component={ContactInput}
                      type="email"
                      label="Email"
                      placeholder="my@email.com"
                      validate={this.validate}
                  />
                  <Button
                      extraClass="form-contacts__button"
                      onSubmit={handleSubmit(this.onSubmitHandler)}
                      title={t('contactForm.button')}
                  />
                </form>
          }
        </div>
    );
  }
}

FormContacts = reduxForm ({
  form: 'contacts',
})(FormContacts);

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight
});

export default withTranslation()(connect(mapStateProps)(FormContacts));