import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import logoKhai from '../../assets/images/logo.png'
import logo from '../../assets/images/logo_white.png';

const links = {
  'school_24': {'score': 'https://khai.edu/ua/abiturientu/shkolyaru/rozrahunok-konkursnogo-balu-dlya-vstupu-za-osvitnim-stupenem-bakalavr/'},
  'school': {'score': 'https://khai.edu/ua/abiturientu/shkolyaru/rozrahunok-konkursnogo-balu-dlya-vstupu-za-osvitnim-stupenem-bakalavr/'},
  'school_zno': {'score': 'https://khai.edu/ua/abiturientu/shkolyaru/rozrahunok-konkursnogo-balu-dlya-vstupu-za-osvitnim-stupenem-bakalavr/'},
  'college_24': {'score': 'https://khai.edu/ua/abiturientu/vypusknikam-tehnikumov/rozrahunok-konkursnogo-balu1/'},
  'college': {'score': 'https://khai.edu/ua/abiturientu/vypusknikam-tehnikumov/rozrahunok-konkursnogo-balu1/'},
  'college_zno': {'score': 'https://khai.edu/ua/abiturientu/vypusknikam-tehnikumov/rozrahunok-konkursnogo-balu1/'},
  'master': {'score': 'https://khai.edu/ua/abiturientu/budushhim-magistram/rozrahunok-konkursnogo-balu2/'},
  'phd': {'score': 'https://khai.edu/ua/nauka/aspirantura-ta-doktorantura/informaciya-dlya-vstupu/'},
};

const Footer = ({ t, isLight, status }) => {
  status = status || 'school';
  return (
      <footer
          className={isLight
              ? 'footer footer--light'
              : 'footer footer--dark'
          }
      >
        <div className="big-container footer__container">
          <div className="footer__container-logo">
            <a href="/" className="header__logo">
              <img src={logo} alt="logo" />
            </a>
            <a href="https://khai.edu/ua/" className="footer__copy-logo" target="_blank">
              <img src={logoKhai} alt="logo" />
            </a>
            <p className="logo-title">
              {t('footer.university1')}
              <br/>
              {t('footer.university2')}
              <br/>
              {t('footer.university3')}
            </p>
          </div>
          <div className="footer__container-links">
            {/*<a*/}
            {/*    target="_blank"*/}
            {/*    className="telegram-link"*/}
            {/*    onClick={() => {*/}
            {/*      window.open("https://t.me/assistant_khai_bot?start="*/}
            {/*          + localStorage.getItem('userCode'))}}*/}
            {/*>*/}
            {/*  {t('footer.telegram')}*/}
            {/*  <FontAwesomeIcon*/}
            {/*      style={{ marginLeft: '10px'}}*/}
            {/*      icon={faPaperPlane}*/}
            {/*  />*/}
            {/*</a>*/}
            <ul className="footer__links-info">
              <li className="footer__list-item">
                <a
                    href={links[status || 'school_24']['score']}
                    target="_blank"
                >
                  {t('footer.formula')}
                </a>
              </li>
              <li className="footer__list-item">
                <a
                    href="https://khai.edu/ua/abiturientu/konsultacijnij-centr-hai/"
                    target="_blank"
                >
                  {t('footer.questions')}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__container-names">
            <span className="footer__names-title">{t('footer.developers')}</span>
            <ul>
              <li className="footer__list-item">
                Team Leader / Back-end Developer - {t(`footer.names.rubel`)} / <a href="mailto:o.rubel@khai.edu">
                o.rubel@khai.edu</a> / <FontAwesomeIcon icon={faPaperPlane}/>
                <a href="https://t.me/asrubel" target="_blank">@asrubel</a>
              </li>
              <li className="footer__list-item">
                DevOps / Back-end Developer - {t(`footer.names.slobodyan`)}
              </li>
              <li className="footer__list-item">
                Front-end Developer - {t(`footer.names.proskura`)}
              </li>
              <li className="footer__list-item">
                Front-end Developer - {t(`footer.names.kuvarina`)}
              </li>
              <li className="footer__list-item">
                UI/UX Designer - {t(`footer.names.shedania`)}
              </li>
              <li className="footer__list-item">
                UI/UX Designer - {t(`footer.names.hizhnyak`)}
              </li>
            </ul>
          </div>
        </div>
      </footer>
  );
};

const mapStateToProps = (state) => ({
  isLight: state.setMainParams.isLight,
  status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateToProps)(Footer));