const initialState = {
  isLight: '',
  allSpecialties: [],
  specialities: [],
  resultSpecialities: [],
  resultSortSpecialities: [],
  language: localStorage.getItem('lang') || 'ukr',
  favourites: localStorage.getItem('favourites')
      ? JSON.parse(localStorage.getItem('favourites'))
      : {'school_24': [], 'school': [], 'school_zno': [],
        'college_24': [], 'college': [], 'college_zno': [],
        'master': [], 'phd': []},
  userCode: localStorage.getItem('userCode') || '',
  origin: localStorage.getItem('origin') || '',
};

export default initialState;