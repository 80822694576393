import React from 'react';
import ErrorTooltip from '../../Shared/ErrorTooltip/ErrorTooltip';
import './ContactInput.scss';
import {connect} from "react-redux";

const ContactInput = (props) => {
  const {
    isLight,
    input,
    label,
    placeholder,
    type,
    meta: { touched, error },
  } = props;

  return (
    <div className={isLight
        ? 'contact-element contact-element--light'
        : 'contact-element contact-element--dark'
    }>
      <input
        className="contact-element__input"
        style={{
          borderBottomColor: error && touched
            ? '#F9003C'
            : isLight ? '#000000' : '#ffffff',
          color: isLight ? '#000000' : '#ffffff',
        }}
        {...input}
        type={type}
        placeholder={placeholder}
      />
        <label
            className="contact-element__label"
            style={{
                color: isLight ? '#000000' : '#ffffff'
            }}
        >
            {label}
        </label>
      {
        touched
        && error
        && <ErrorTooltip error={error} />
      }
    </div>
  )
};

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

export default connect(mapStateProps)(ContactInput);
