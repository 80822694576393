import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { campaignStages } from "../../assets/campaignStages";
import './StepsLanding.scss';

const StepsLanding = ({ t, isLight, status }) => {
  status = status || 'school_24';
  return (
    <section
      className={isLight
        ? 'steps-container big-container steps-container--light'
        : 'steps-container big-container steps-container'}
    >
      <h2 className="steps__title">{t('stages.title')}</h2>
      <div className="steps">
        {
          campaignStages[status].map((stage, index) => (
            <div
              key={index}
              className={index % 2 !== 0 ? 'step step--right' : 'step'}
            >
              <div className="step__label">
                {t('stages.' + status + '.' + stage.step)}
              </div>
              <div className="step__box">
                <div className="step__box-interior">
                  <span className="step__box-index">
                    {index + 1}
                  </span>
                  <span className="step__box-date">
                    {stage.date}
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
};

const mapStateToProps = (state) => ({
  isLight: state.setMainParams.isLight,
  status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateToProps)(StepsLanding));