import React from 'react';
import { withTranslation } from "react-i18next";
import { Field, reduxForm } from 'redux-form';
import ExamsInput from '../../Shared/ExamsInput/ExamsInput';
import Button from "../../Shared/Button/Button";
import SearchSelectSpeciality from "../../SearchSelectSpeciality/SearchSelectSpeciality";
import CheckInput from "../../Shared/CheckInput/CheckInput";

const labels = [
  'ukrainian',
  'math',
  'foreign',
  'physics',
  'biology',
  'history',
  'chemistry'
];

class FormCollege extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidForm: false,
      isNumberExams: false,
      inputValues: new Map(),
      speciality_college: '',
      isValidateSpecialty: true,
    };

    this.validate = this.validate.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.selectSpecialty = this.selectSpecialty.bind(this);
    this.validateSpecialty = this.validateSpecialty.bind(this);
    this.validateMarks = this.validateMarks.bind(this);
  }

  validate(...values) {
    const value = values[0];
    const name = values[3];
    const { t } = this.props;
    const { isNumberExams, inputValues } = this.state;
    let isError = false;
    let error;

    switch (name) {
      case 'ukrainian':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,5,45)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        } else {
          error = t('startForm.moderateUkrError');
          isError = true;
          inputValues.delete(name);
        }
        break;
      case 'math':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,32)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        } else {
          error = t('startForm.moderateMathError');
          isError = true;
          inputValues.delete(name);
        }
        break;
      case 'history':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,6,54)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'biology':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,6,46)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'chemistry':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,40)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      case 'foreign':
      case 'physics':
        if (value) {
          if (!this.setValidateValue(value,100,200) && !this.setValidateValue(value,4,32)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
        break;
      default:
        if (value) {
          if (!this.setValidateValue(value,100,200)) {
            error = t('startForm.errorLabel');
            isError = true;
            inputValues.delete(name);
          } else {
            inputValues.set(name, value);
          }
        }
    }

    if (isError) {
      return error;
    }

    if (!isNumberExams) {
      this.setState({
        isValidForm: true,
      });
    }

    //if (inputValues.size === 3 || inputValues.size === 4) {
    if (inputValues.size >= 3) {
      this.setState({ isNumberExams: false });
    }
  }

  setValidateValue(value, min, max) {
    return (+value >= min && +value <= max);
  }

  validateMarks() {
    //if (inputValues.size !== 3 && inputValues.size !== 4) {
    const { inputValues } = this.state;
    if (inputValues.size < 3) {
      this.setState({ isNumberExams: true });
    }

    this.validateSpecialty()
  }

  validateSpecialty() {
    const { speciality_college } = this.state;
    this.setState({
      isValidateSpecialty: (speciality_college && speciality_college !== ''),
    });
  }

  onSubmitHandler(values) {
    const { isNumberExams, isValidateSpecialty, speciality_college } = this.state;
    const data = {
      subjects: labels
          .filter(label => label in values)
          .reduce((obj, label) => {
            return {...obj, [label]: parseInt(values[label])};}, {}),
      add_info: {
        speciality_college: speciality_college,
        courses: values.courses === true,
        /*mean_score_college: 0 parseFloat(values.mean_score_college)*/
      }
    }
    if (isValidateSpecialty && !isNumberExams) {
      this.props.getResultSpecialities(data);
    }
  }

  selectSpecialty(specialty) {
    this.setState({
      speciality_college: specialty.substr(0,3),
      isValidateSpecialty: true
    });
  }

  render () {
    const { t, handleSubmit } = this.props;
    const { isValidateSpecialty, isValidForm, isNumberExams, inputValues } = this.state;

    return (
        <form
            className="data-form"
            onSubmit={handleSubmit(this.onSubmitHandler)}
            autoComplete="off"
        >
          <div className="data-form__row data-form__row--title">
            {t('startForm.exams')}
            <span className={isNumberExams ? 'data-form__error' : 'data-form__hidden'}>
              {t('startForm.errorNMT')}
            </span>
          </div>
          <div className="data-form__row data-form__row--exam">
            {
              labels.map((label) => (
                  <Field
                      key={label}
                      name={label}
                      component={ExamsInput}
                      disabled={inputValues.size === 3}
                      type="text"
                      label={t(`startForm.subjects.${label}`)}
                      validate={this.validate}
                  />
              ))
            }
          </div>

          <div className="data-form__row">
            {/*<Field
                label={t('startForm.meanScoreCollege')}
                name="mean_score_college"
                component={ExamsInput}
                type="text"
                placeholder={t('startForm.placeholderWorks')}
                validate={this.validate}
            />*/}
            <Field
                label={t('startForm.courses')}
                name="courses"
                component={CheckInput}
            />
            <SearchSelectSpeciality
                label={t('startForm.specialityCollege')}
                selectSpecialty={this.selectSpecialty}
                isValid={isValidateSpecialty}
                error={t('startForm.selectError')}
            />
          </div>

          <div className="data-form__submit">
            <Button
                extraClass="data-form__button"
                onSubmit={handleSubmit(this.onSubmitHandler)}
                validate={this.validateMarks}
                title={t('startForm.button')}
            />
            <span
                style={{
                  display: isValidForm ? 'none' : 'block',
                }}
                className="data-form__submit-error"
            >
              {t('startForm.validateForm')}
            </span>
          </div>
        </form>
    );
  }
}

FormCollege = reduxForm({
  form: 'formCollege',
})(FormCollege);

export default withTranslation()(FormCollege);