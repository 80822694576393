import React from 'react';

class DropDownBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: false,
      isFocus: false,
      classSelector: '',
    };
  }

  openMenu() {
    this.setState({
      isOpenMenu: true,
      isFocus: true
    });

    document.addEventListener('mousedown', this.onClickOutside);
  }

  closeMenu() {
    this.setState({ isOpenMenu: false });
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onClickOutside(event) {
    const { classSelector } = this.state;
    const dropDownItem = document.querySelector(classSelector);
    let targetElement = event.target;

    this.checkSelector(dropDownItem, targetElement);
  }

  checkSelector(dropDownItem, targetElement) {
    do {
      if (targetElement === dropDownItem) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);

    this.closeMenu();
  }
}

export default DropDownBase;
