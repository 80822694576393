import React from 'react';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import listIcon from '../../assets/images/list.svg';
import listIconLight from '../../assets/images/list-light.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faPaperPlane, faStar } from '@fortawesome/free-solid-svg-icons';
import { eduTerms } from "../../assets/eduTerms";
import './DetailedCard.scss';
import style from "radium/es/components/style";

class DetailedCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      favourite_programs: []
    }

    this.getInterested = this.getInterested.bind(this);
  }

  componentWillUnmount() {
    const { update } = this.props;
    update();
  }

  getInterested(id_pro) {
    const { interested } = this.props;
    const { favourite_programs } = this.state;
    interested(id_pro);
    favourite_programs.push(id_pro);
    this.setState({ favourite_programs })
  }

  render() {
    const { proposition, favourites, status, lang, t, isLight } = this.props;
    const { favourite_programs } = this.state;
    const fa = (status in favourites) ? favourites[status] : favourites['school_24']
    const opts = {
      height: '390',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        //autoplay: true,
      },
    };

    return (
        <div className={isLight
            ? 'detailed detailed--light'
            : 'detailed detailed--dark'}>
          <article className="detailed-description">
            <h3 className="detailed-description__name">
              {
                `${proposition.speciality_name[lang]} - ${proposition.code}` +
                `${['BACHELOR'].includes(proposition.level) && ['school_24','school','school_zno','college_24','college','college_zno'].includes(status) ?
                    ' [' + t('detailed.pass_score') 
                    + ' 2021 - ' + proposition.pass_scores['bachelor']['2021']
                    + ', 2022 - ' + proposition.pass_scores['bachelor']['2022']
                    + ', 2023 - ' + proposition.pass_scores['bachelor']['2023'] + ']'
                    : ''}` +
                `${['JUN_BACHELOR'].includes(proposition.level) ?
                    ' [' + t('detailed.pass_score')
                    + ' 2021 - ' + proposition.pass_scores['junior_bachelor']['2021']
                    + ', 2022 - ' + proposition.pass_scores['junior_bachelor']['2022']
                    + ', 2023 - ' + proposition.pass_scores['junior_bachelor']['2023'] + ']'
                    : ''}`
              }
            </h3>
          </article>

          <div className="detailed-programs">
            <div className="detailed-programs__part-name">
              {t('detailed.programs')}
            </div>
            <ul className="detailed-programs__program">
              <li className="program">
                <span className={"program__name"}
                      style={['JUN_BACHELOR'].includes(proposition.level)
                          ? {'background': '#0BA960'} : {}}>
                      {
                        /*(index + 1) + '. ' + */
                          proposition.proposition_name[lang] + ' [' + t("detailed.level") + ' '
                          + t("detailed." + eduTerms[status][proposition.level]['level'])
                          + '; ' + t("detailed.term") + ' '
                            + (['master'].includes(status)
                                ? t("detailed." + eduTerms[status][proposition.level]['term'][0])
                                + (proposition.sci_master === true
                                    ? t("detailed." + eduTerms[status][proposition.level]['term'][1]) : '')
                                : t("detailed." + eduTerms[status][proposition.level]['term'])) + ']'
                      }
                    </span>
                <div className="program__links">
                  {
                    proposition.links.edu_program ?
                        <a className="program__links-url" href={proposition.links.edu_program} target="_blank">
                          <img
                              className="program__links-icon"
                              src={isLight ? listIconLight : listIcon}
                              alt="education program"
                          />
                          {t("detailed.program")}
                        </a> : ''
                  }
                  {
                    (['college'].includes(status)) ?
                        proposition.links.curriculum_college ?
                            <a className="program__links-url" href={proposition.links.curriculum_college} target="_blank">
                              <img
                                  className="program__links-icon"
                                  src={isLight ? listIconLight : listIcon}
                                  alt="education program"
                              />
                              {t("detailed.curriculum")}
                            </a> : ''
                        : proposition.links.curriculum ?
                            <a className="program__links-url" href={proposition.links.curriculum} target="_blank">
                              <img
                              className="program__links-icon"
                              src={isLight ? listIconLight : listIcon}
                              alt="education program"
                            />
                              {t("detailed.curriculum")}
                            </a> : ''
                  }
                  {
                    proposition.links.department ?
                        <a className="program__links-url" href={proposition.links.department} target="_blank">
                          <FontAwesomeIcon
                              className={isLight
                                  ? 'program__links-icon program__links-icon--light'
                                  : 'program__links-icon program__links-icon--dark'}
                              icon={faUniversity}/>
                          {t("detailed.department")}
                        </a> : ''
                  }
                  {
                    proposition.links.telegram ?
                        <a className="program__links-url" href={proposition.links.telegram} target="_blank">
                          <FontAwesomeIcon
                              className={isLight
                                  ? 'program__links-icon program__links-icon--light'
                                  : 'program__links-icon program__links-icon--dark'}
                              icon={faPaperPlane}/>
                          Telegram
                        </a> : ''
                  }
                  {
                    (['college'].includes(status)) ?
                        proposition.links.test_college ?
                            <a className="program__links-url" href={proposition.links.test_college} target="_blank">
                              <FontAwesomeIcon
                                  className={isLight
                                      ? 'program__links-icon program__links-icon--light'
                                      : 'program__links-icon program__links-icon--dark'}
                                  icon={faPaperPlane}
                              />
                              {t("detailed.test_college")}
                            </a> : '' : ''
                  }
                </div>
                <p className='program__description'>
                  {proposition.info[lang]}
                </p>
                {
                  proposition.links.youtube ?
                      <YouTube className='program__embed-video'
                               videoId={proposition.links.youtube['ukr']} opts={opts}
                               onReady={this._onReady} /> : ''
                }
                {
                  fa.some((favourite) => favourite.id_pro === proposition.id_pro)
                  || favourite_programs.some((favourite) => favourite === proposition.id_pro)
                      ? <span className={isLight
                      ? "favourites favourites--light"
                      : "favourites favourites--dark"}>
                            <FontAwesomeIcon
                                style={{marginRight: '10px'}}
                                icon={faStar}/>
                        {t('detailed.favourites')}
                          </span>
                      : <button
                          className={isLight
                              ? 'program__favourites program__favourites--light'
                              : 'program__favourites program__favourites--dark'}
                          onClick={() => this.getInterested(proposition.id_pro)}>
                        {t('detailed.favourites')}
                      </button>
                }
              </li>
            </ul>

            {/*<ul className="detailed-programs__program">
              {speciality.programs.map((program, index) => (
                  <li key={index} className="program">

                  </li>
              ))}
            </ul>*/}
          </div>
        </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
  favourites: state.specialities.favourites,
  lang: state.setMainParams.language,
  status: localStorage.getItem('status')
});

export default withTranslation()(connect(mapStateProps)(DetailedCard));