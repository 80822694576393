import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import './Button.scss';

const Button = ({ title, onSubmit, validate, isLight }) => (
  <button
    className={isLight
      ? 'Button Button--light'
      : 'Button Button--dark'}
    type="submit"
    onMouseDown={validate}
    onClick={onSubmit}
  >
    {title}
  </button>
);

Button.propTypes = {
  title: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
  disabled: propTypes.bool,
  extraClass: propTypes.string,
};

const mapStateProps = (state) => ({
  isLight: state.setMainParams.isLight,
});

export default connect(mapStateProps)(Button);
