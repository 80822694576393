export const specialities = [

  {"code": "121", "ukr": "Інженерія програмного забезпечення", "rus": "Инженерия программного обеспечения", "eng": "Software Engineering"},
  {"code": "122", "ukr": "Комп'ютерні науки", "rus": "Компьютерные науки", "eng": "Computer Science"},
  {"code": "123", "ukr": "Комп’ютерна інженерія", "rus": "Компьютерная инженерия", "eng": "Computer Engineering"},
  {"code": "124", "ukr": "Системний аналіз", "rus": "Системный анализ", "eng": "System Analysis"},
  {"code": "125", "ukr": "Кібербезпека", "rus": "Кибербезопасность", "eng": "Cybersecurity"},
  {"code": "126", "ukr": "Інформаційні системи та технології", "rus": "Информационные системы и технологии", "eng": "Information Systems and Technologies"},
  {"code": "131", "ukr": "Прикладна механіка", "rus": "Прикладная механика", "eng": "Applied Mechanics"},
  {"code": "132", "ukr": "Матеріалознавство", "rus": "Материаловедение", "eng": "Materials Science"},
  {"code": "133", "ukr": "Галузеве машинобудування", "rus": "Отраслевое машиностроение", "eng": "Industrial Engineering"},
  {"code": "134", "ukr": "Авіаційна та ракетно-космічна техніка", "rus": "Авиационная и ракетно-космическая техника", "eng": "Aviation and space rocket technology"},
  {"code": "135", "ukr": "Суднобудування", "rus": "Судостроение", "eng": "Shipbuilding"},
  {"code": "136", "ukr": "Металургія", "rus": "Металлургия", "eng": "Metallurgy"},
  {"code": "141", "ukr": "Електроенергетика, електротехніка та електромеханіка", "rus": "Электроэнергетика, электротехника и электромеханика", "eng": "Electric power, electrical engineering and electromechanics"},
  {"code": "142", "ukr": "Енергетичне машинобудування", "rus": "Энергетическое машиностроение", "eng": "Energy Engineering"},
  {"code": "143", "ukr": "Атомна енергетика", "rus": "Атомная энергетика", "eng": "Nuclear Power"},
  {"code": "144", "ukr": "Теплоенергетика", "rus": "Теплоэнергетика", "eng": "Thermal Power"},
  {"code": "145", "ukr": "Гідроенергетика", "rus": "Гидроэнергетика", "eng": "Hydropower"},
  {"code": "151", "ukr": "Автоматизація та комп’ютерно-інтегровані технології", "rus": "Автоматизация и компьютерно-интегрированные технологии", "eng": "Automation and computer-integrated technologies"},
  {"code": "152", "ukr": "Метрологія та інформаційно-вимірювальна техніка", "rus": "Метрология и информационно-измерительная техника", "eng": "Metrology and information-measuring equipment"},
  {"code": "153", "ukr": "Мікро- та наносистемна техніка", "rus": "Микро- и наносистемна техника", "eng": "Micro- and nanosystem technology"},
  {"code": "161", "ukr": "Хімічні технології та інженерія", "rus": "Химические технологии и инженерия", "eng": "Chemical Technology and Engineering"},
  {"code": "162", "ukr": "Біотехнології та біоінженерія", "rus": "Биотехнологии и биоинженерия", "eng": "Biotechnology and Bioengineering"},
  {"code": "163", "ukr": "Біомедична інженерія", "rus": "Биомедицинская инженерия", "eng": "Biomedical Engineering"},
  {"code": "171", "ukr": "Електроніка", "rus": "Электроника", "eng": "Electronics"},
  {"code": "172", "ukr": "Телекомунікації та радіотехніка", "rus": "Телекоммуникации и радиотехника", "eng": "Telecommunications and Radio Engineering"},
  {"code": "173", "ukr": "Авіоніка", "rus": "Авионика", "eng": "Avionics"},
  {"code": "011", "ukr": "Освітні, педагогічні науки", "rus": "Образовательные, педагогические науки", "eng": "Educational, pedagogical sciences"},
  {"code": "012", "ukr": "Дошкільна освіта", "rus": "Дошкольное образование", "eng": "Preschool education"},
  {"code": "013", "ukr": "Початкова освіта", "rus": "Начальное образование", "eng": "Primary education"},
  {"code": "014", "ukr": "Середня освіта", "rus": "Среднее образование", "eng": "Secondary education"},
  {"code": "015", "ukr": "Професійна освіта", "rus": "Профессиональное образование", "eng": "Vocational education"},
  {"code": "016", "ukr": "Спеціальна освіта", "rus": "Специальное образование", "eng": "Special education"},
  {"code": "017", "ukr": "Фізична культура і спорт", "rus": "Физическая культура и спорт", "eng": "Physical Culture and Sports"},
  {"code": "021", "ukr": "Аудіовізуальне мистецтво та виробництво", "rus": "Аудиовизуальное искусство и производство", "eng": "Audiovisual Art and production"},
  {"code": "022", "ukr": "Дизайн", "rus": "Дизайн", "eng": "Design"},
  {"code": "023", "ukr": "Образотворче мистецтво, декоративне мистецтво, реставрація", "rus": "Изобразительное искусство, декоративное искусство, реставрация", "eng": "Fine Arts, decorative Arts, restoration"},
  {"code": "024", "ukr": "Хореографія", "rus": "Хореография", "eng": "Choreography"},
  {"code": "025", "ukr": "Музичне мистецтво", "rus": "Музыкальное искусство", "eng": "Musical Arts"},
  {"code": "026", "ukr": "Сценічне мистецтво", "rus": "Сценическое искусство", "eng": "Performing Arts"},
  {"code": "027", "ukr": "Музеєзнавство, пам’яткознавство", "rus": "Музееведение, памятниковедение", "eng": "Museology, Monument Studies"},
  {"code": "028", "ukr": "Менеджмент соціокультурної діяльності", "rus": "Менеджмент социокультурной деятельности", "eng": "Management of socio-cultural activities"},
  {"code": "029", "ukr": "Інформаційна, бібліотечна та архівна справа", "rus": "Информационная, библиотечная и архивное дело", "eng": "Information, Library and Archival Affairs"},
  {"code": "031", "ukr": "Релігієзнавство", "rus": "Религиеведение", "eng": "Religion"},
  {"code": "032", "ukr": "Історія та археологія", "rus": "История и археология", "eng": "History and Archeology"},
  {"code": "033", "ukr": "Філософія", "rus": "Философия", "eng": "Philosophy"},
  {"code": "034", "ukr": "Культурологія", "rus": "Культурология", "eng": "Culturology"},
  {"code": "035", "ukr": "Філологія", "rus": "Филология", "eng": "Philology"},
  {"code": "041", "ukr": "Богослов’я", "rus": "Богословие", "eng": "Theology"},
  {"code": "051", "ukr": "Економіка", "rus": "Экономика", "eng": "Economics"},
  {"code": "052", "ukr": "Політологія", "rus": "Политология", "eng": "Political Science"},
  {"code": "053", "ukr": "Психологія", "rus": "Психология", "eng": "Psychology"},
  {"code": "054", "ukr": "Соціологія", "rus": "Социология", "eng": "Sociology"},
  {"code": "061", "ukr": "Журналістика", "rus": "Журналистика", "eng": "Journalism"},
  {"code": "071", "ukr": "Облік і оподаткування", "rus": "Учет и налогообложение", "eng": "Accounting and Taxation"},
  {"code": "072", "ukr": "Фінанси, банківська справа та страхування", "rus": "Финансы, банковское дело и страхование", "eng": "Finance, Banking and Insurance"},
  {"code": "073", "ukr": "Менеджмент", "rus": "Менеджмент", "eng": "Management"},
  {"code": "075", "ukr": "Маркетинг", "rus": "Маркетинг", "eng": "Marketing"},
  {"code": "076", "ukr": "Підприємництво, торгівля та біржова діяльність", "rus": "Предпринимательство, торговля и биржевая деятельность", "eng": "Entrepreneurship, trade and exchange activities"},
  {"code": "081", "ukr": "Право", "rus": "Право", "eng": "Law"},
  {"code": "091", "ukr": "Біологія", "rus": "Биология", "eng": "Biology"},
  {"code": "101", "ukr": "Екологія", "rus": "Экология", "eng": "Ecology"},
  {"code": "102", "ukr": "Хімія", "rus": "Химия", "eng": "Chemistry"},
  {"code": "103", "ukr": "Науки про Землю", "rus": "Науки о Земле", "eng": "Geosciences"},
  {"code": "104", "ukr": "Фізика та астрономія", "rus": "Физика и астрономия", "eng": "Physics and Astronomy"},
  {"code": "105", "ukr": "Прикладна фізика та наноматеріали", "rus": "Прикладная физика и наноматериалы", "eng": "Applied Physics and Nanomaterials"},
  {"code": "106", "ukr": "Географія", "rus": "География", "eng": "Geography"},
  {"code": "111", "ukr": "Математика", "rus": "Математика", "eng": "Mathematics"},
  {"code": "112", "ukr": "Статистика", "rus": "Статистика", "eng": "Statistics"},
  {"code": "113", "ukr": "Прикладна математика", "rus": "Прикладная математика", "eng": "Applied Mathematics"},
  {"code": "181", "ukr": "Харчові технології", "rus": "Пищевые технологии", "eng": "Food Technologies"},
  {"code": "182", "ukr": "Технології легкої промисловості", "rus": "Технологии легкой промышленности", "eng": "Light Industry Technologies"},
  {"code": "183", "ukr": "Технології захисту навколишнього середовища", "rus": "Технологии защиты окружающей среды", "eng": "Environmental protection technologies"},
  {"code": "184", "ukr": "Гірництво", "rus": "Горное дело", "eng": "Mining"},
  {"code": "185", "ukr": "Нафтогазова інженерія та технології", "rus": "Нефтегазовая инженерия и технологии", "eng": "Oil and Gas Engineering and Technology"},
  {"code": "186", "ukr": "Видавництво та поліграфія", "rus": "Издательство и полиграфия", "eng": "Publishing and Printing"},
  {"code": "187", "ukr": "Деревообробні та меблеві технології", "rus": "Деревообрабатывающие и мебельные технологии", "eng": "Woodworking and furniture technologies"},
  {"code": "191", "ukr": "Архітектура та містобудування", "rus": "Архитектура и градостроительство", "eng": "Architecture and Urban Planning"},
  {"code": "192", "ukr": "Будівництво та цивільна інженерія", "rus": "Строительство и гражданская инженерия", "eng": "Construction and Civil Engineering"},
  {"code": "193", "ukr": "Геодезія та землеустрій", "rus": "Геодезия и землеустройство", "eng": "Geodesy and Land Management"},
  {"code": "194", "ukr": "Гідротехнічне будівництво, водна інженерія та водні технології", "rus": "Гидротехническое строительство, водная инженерия и водные технологии", "eng": "Hydraulic engineering, water engineering and water technologies"},
  {"code": "201", "ukr": "Агрономія", "rus": "Агрономия", "eng": "Agronomy"},
  {"code": "202", "ukr": "Захист і карантин рослин", "rus": "Защита и карантин растений", "eng": "Plant protection and quarantine"},
  {"code": "203", "ukr": "Садівництво та виноградарство", "rus": "Садоводство и виноделие", "eng": "Horticulture and viticulture"},
  {"code": "204", "ukr": "Технологія виробництва і переробки продукції тваринництва", "rus": "Технология производства и переработки продукции животноводства", "eng": "Technology of production and processing of livestock products"},
  {"code": "205", "ukr": "Лісове господарство", "rus": "Лесное хозяйство", "eng": "Forestry"},
  {"code": "206", "ukr": "Садово-паркове господарство", "rus": "Садово-парковое хозяйство", "eng": "Landscapes"},
  {"code": "207", "ukr": "Водні біоресурси та аквакультура", "rus": "Водные биоресурсы и аквакультура", "eng": "Aquatic Bioresources and Aquaculture"},
  {"code": "208", "ukr": "Агроінженерія", "rus": "Агроинженерия", "eng": "Agroengineering"},
  {"code": "211", "ukr": "Ветеринарна медицина", "rus": "Ветеринарная медицина", "eng": "Veterinary Medicine"},
  {"code": "212", "ukr": "Ветеринарна гігієна, санітарія і експертиза", "rus": "Ветеринарная гигиена, санитария и экспертиза", "eng": "Veterinary hygiene, sanitation and examination"},
  {"code": "221", "ukr": "Стоматологія", "rus": "Стоматология", "eng": "Dentistry"},
  {"code": "222", "ukr": "Медицина", "rus": "Медицина", "eng": "Medicine"},
  {"code": "223", "ukr": "Медсестринство", "rus": "Медсестринство", "eng": "Nursing"},
  {"code": "224", "ukr": "Технології медичної діагностики та лікування", "rus": "Технологии медицинской диагностики и лечения", "eng": "Technologies of medical diagnostics and treatment"},
  {"code": "225", "ukr": "Медична психологія", "rus": "Медицинская психология", "eng": "Medical psychology"},
  {"code": "226", "ukr": "Фармація, промислова фармація", "rus": "Фармация, промышленная фармация", "eng": "Pharmacy, industrial pharmacy"},
  {"code": "227", "ukr": "Фізична терапія, ерготерапія", "rus": "Физическая терапия, Эрготерапия", "eng": "Physical therapy, occupational therapy"},
  {"code": "228", "ukr": "Педіатрія", "rus": "Педиатрия", "eng": "Pediatrics"},
  {"code": "229", "ukr": "Громадське здоров'я", "rus": "Общественное здоровье", "eng": "Public Health"},
  {"code": "231", "ukr": "Соціальна робота", "rus": "Социальная работа", "eng": "Social work"},
  {"code": "232", "ukr": "Соціальне забезпечення", "rus": "Социальное обеспечение", "eng": "Social service"},
  {"code": "241", "ukr": "Готельно-ресторанна справа", "rus": "Гостинично-ресторанное дело", "eng": "Hotel and restaurant business"},
  {"code": "242", "ukr": "Туризм", "rus": "Туризм", "eng": "Tourism"},
  {"code": "251", "ukr": "Державна безпека", "rus": "Государственная безопасность", "eng": "State Security"},
  {"code": "252", "ukr": "Безпека державного кордону", "rus": "Безопасность государственной границы", "eng": "State Border Security"},
  {"code": "253", "ukr": "Військове управління", "rus": "Военное управление", "eng": "Military Administration"},
  {"code": "254", "ukr": "Забезпечення військ (сил)", "rus": "Обеспечение войск (сил)", "eng": "Provision of troops (forces)"},
  {"code": "255", "ukr": "Озброєння та військова техніка", "rus": "Вооружение и военная техника", "eng": "Weapons and military equipment"},
  {"code": "256", "ukr": "Національна безпека", "rus": "Национальная безопасность", "eng": "National Security"},
  {"code": "261", "ukr": "Пожежна безпека", "rus": "Пожарная безопасность", "eng": "Fire safety"},
  {"code": "262", "ukr": "Правоохоронна діяльність", "rus": "Правоохранительная деятельность", "eng": "Law enforcement"},
  {"code": "263", "ukr": "Цивільна безпека", "rus": "Гражданская безопасность", "eng": "Civil Security"},
  {"code": "271", "ukr": "Річковий та морський транспорт", "rus": "Речной и морской транспорт", "eng": "River and sea transport"},
  {"code": "272", "ukr": "Авіаційний транспорт", "rus": "Авиационный транспорт", "eng": "Air transport"},
  {"code": "273", "ukr": "Залізничний транспорт", "rus": "Железнодорожный транспорт", "eng": "Railway transport"},
  {"code": "274", "ukr": "Автомобільний транспорт", "rus": "Автомобильный транспорт", "eng": "Automobile Transport"},
  {"code": "275", "ukr": "Транспортні технології", "rus": "Транспортные технологии", "eng": "Transport technologies"},
  {"code": "281", "ukr": "Публічне управління та адміністрування", "rus": "Публичное управление и администрирование", "eng": "Public Management and Administration"},
  {"code": "291", "ukr": "Міжнародні відносини, суспільні комунікації та регіональні студії", "rus": "Международные отношения, общественные коммуникации и региональные студии", "eng": "International Relations, Public Communications and Regional Studies"},
  {"code": "292", "ukr": "Міжнародні економічні відносини", "rus": "Международные экономические отношения", "eng": "International Economic Relations"},
  {"code": "293", "ukr": "Міжнародне право", "rus": "Международное право", "eng": "International Law"}
];