export const eduTerms = {
    school_24: {
        BACHELOR: {
            level: "bachelor",
            term: "3_years_10_months"
        },
        JUN_BACHELOR: {
            level: "junior_bachelor",
            term: "1_year_10_months"
        }
    },
    school: {
        BACHELOR: {
            level: "bachelor",
            term: "3_years_10_months"
        },
        JUN_BACHELOR: {
            level: "junior_bachelor",
            term: "1_year_10_months"
        }
    },
    school_zno: {
        BACHELOR: {
            level: "bachelor",
            term: "3_years_10_months"
        },
        JUN_BACHELOR: {
            level: "junior_bachelor",
            term: "1_year_10_months"
        }
    },
    college_24: {
        BACHELOR: {
            level: "bachelor",
            term: "2_years_10_months"
        }
    },
    college: {
        BACHELOR: {
            level: "bachelor",
            term: "2_years_10_months"
        }
    },
    college_zno: {
        BACHELOR: {
            level: "bachelor",
            term: "2_years_10_months"
        }
    },
    master: {
        MASTER: {
            level: "master",
            term: ["1_year_4_months", "1_year_9_months"]
        }
    },
    phd: {
        PhD: {
            level: "phd",
            term: "4_years"
        }
    }
};